$(() => {

    $('#faq_search').on('keyup', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            filterFaq();
        }
    });

    $('body').on('click', '#btn_search_faq', function (event) {
        filterFaq();
    });

    function filterFaq() {
        let faqSearch = $('#faq_search').val();
        let section_id = $('#section_id').val();
        console.log(faqSearch);
        $.ajax({
            type: 'GET',
            url: $('#search_faq_url').val(),
            data: {faq_search: faqSearch, section_id: section_id},
            success: function (response) {
                if (response.success) {
                    $('#faq_filter').html(response.view);
                }
            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        });
    }
});
